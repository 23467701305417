var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"inspirationObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja inspiracji")]),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Język"}},[_c('validation-provider',{attrs:{"name":"Język","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"v-language-select","label":"value","placeholder":"Wybierz język","clearable":false,"deselectFromDropdown":false,"options":_vm.languages},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Klucz unikalny inspiracji (slug - pozostawienie pustego pola spowoduje automatyczne wygenerowanie sluga)"}},[_c('validation-provider',{attrs:{"name":"Klucz unikalny inspiracji (slug)","rules":"alpha_dash|min:4|max:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-slug","placeholder":"Klucz unikalny inspiracji (slug)","disabled":!_vm.lang},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Tagi"}},[_c('validation-provider',{attrs:{"name":"Tagi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"v-category","label":"name","placeholder":"Wpisz tagi i zatwierdź enterem","taggable":true,"clearable":false,"multiple":true,"deselectFromDropdown":false,"disabled":!_vm.lang},model:{value:(_vm.form.tags),callback:function ($$v) {_vm.$set(_vm.form, "tags", $$v)},expression:"form.tags"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Opis SEO"}},[_c('validation-provider',{attrs:{"name":"Opis SEO","rules":"required|min:2|max:160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"disabled":!_vm.lang,"rows":"3"},model:{value:(_vm.form.seo_description),callback:function ($$v) {_vm.$set(_vm.form, "seo_description", $$v)},expression:"form.seo_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Powiązane posty"}},[_c('v-select',{attrs:{"label":"title","name":"inspiration-posts","placeholder":"Wybierz posty","id":"v-post-selection","clearable":false,"closeOnSelect":false,"multiple":true,"deselectFromDropdown":false,"options":_vm.availablePosts},model:{value:(_vm.form.posts),callback:function ($$v) {_vm.$set(_vm.form, "posts", $$v)},expression:"form.posts"}})],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Treść inspiracji")]),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Tytuł"}},[_c('validation-provider',{attrs:{"name":"Tytuł","rules":"required|min:2|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-image-title","placeholder":"Tytuł","disabled":!_vm.lang},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-0",attrs:{"label":"Zdjęcie tytułowe"}},[_c('b-button',{attrs:{"disabled":!_vm.lang},on:{"click":function($event){return _vm.toggleShowModal('image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Zdjęcie tytułowe","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"d-block my-1"},[_vm._v("Zalecane proporcje zdjęcia to 16:9 - zdjęcie panoramiczne")]),(_vm.files.image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.image.file),expression:"files.image.file"}],attrs:{"type":"hidden","name":"Zdjęcie tytułowe"},domProps:{"value":(_vm.files.image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.image),expression:"files.image"}],attrs:{"type":"hidden","name":"Zdjęcie tytułowe"},domProps:{"value":(_vm.files.image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "image", $event.target.value)}}}),(_vm.showImagesModal.image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.image},on:{"closeModal":function($event){return _vm.toggleShowModal('image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.image)?_c('ImagePreview',{attrs:{"image":_vm.files.image},on:{"deleteImage":function () { return _vm.handleDelete('image'); }}}):_vm._e()],1),_c('b-form-group',{staticClass:"mt-0",attrs:{"label":"Zdjęcie mobile"}},[_c('b-button',{attrs:{"disabled":!_vm.lang},on:{"click":function($event){return _vm.toggleShowModal('image_mobile')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('i',{staticClass:"d-block my-1"},[_vm._v("Zalecane proporcje zdjęcia to 9:16 - zdjęcie portretowe")]),_c('validation-provider',{attrs:{"name":"Zdjęcie mobile","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.image_mobile)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.image_mobile.file),expression:"files.image_mobile.file"}],attrs:{"type":"hidden","name":"Zdjęcie mobile"},domProps:{"value":(_vm.files.image_mobile.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.image_mobile, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.image_mobile),expression:"files.image_mobile"}],attrs:{"type":"hidden","name":"Zdjęcie mobile"},domProps:{"value":(_vm.files.image_mobile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "image_mobile", $event.target.value)}}}),(_vm.showImagesModal.image_mobile)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.image_mobile},on:{"closeModal":function($event){return _vm.toggleShowModal('image_mobile')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'image_mobile'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.image_mobile)?_c('ImagePreview',{attrs:{"image":_vm.files.image_mobile},on:{"deleteImage":function () { return _vm.handleDelete('image_mobile'); }}}):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Krótki opis"}},[_c('validation-provider',{attrs:{"name":"Krótki opis","rules":"required|min:3|max:400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"disabled":!_vm.lang,"rows":"3"},model:{value:(_vm.form.short_description),callback:function ($$v) {_vm.$set(_vm.form, "short_description", $$v)},expression:"form.short_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Treść"}},[_c('validation-provider',{attrs:{"name":"Treść","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.editorOption,"disabled":!_vm.lang},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Produkty w inspiracji")]),_c('b-form-group',{attrs:{"label":"Produkty"}},[_vm._l((_vm.form.products),function(item,index){return _c('b-col',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-form-group',{attrs:{"label":"SKU","label-for":("year-" + (item.id) + "-" + (item.id))}},[_c('validation-provider',{attrs:{"name":("SKU #" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":("product-symbol-" + (item.id) + "-" + (item.id)),"placeholder":"SKU","label":"sku","multiple":false,"closeOnSelect":true,"options":_vm.skuProducts},on:{"search":_vm.fetchBySku},model:{value:(_vm.form.products[index].product_symbol),callback:function ($$v) {_vm.$set(_vm.form.products[index], "product_symbol", $$v)},expression:"form.products[index].product_symbol"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v("Desktop")]),_c('b-form-group',{attrs:{"label":"X%","label-for":("x-" + (item.id) + "-" + (item.id))}},[_c('validation-provider',{attrs:{"name":("X #" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":("x-" + (item.id) + "-" + (item.id)),"placeholder":"X%"},model:{value:(_vm.form.products[index].x),callback:function ($$v) {_vm.$set(_vm.form.products[index], "x", $$v)},expression:"form.products[index].x"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Y%","label-for":("y-" + (item.id) + "-" + (item.id))}},[_c('validation-provider',{attrs:{"name":("X #" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":("y-" + (item.id) + "-" + (item.id)),"placeholder":"Y%"},model:{value:(_vm.form.products[index].y),callback:function ($$v) {_vm.$set(_vm.form.products[index], "y", $$v)},expression:"form.products[index].y"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.files.image)?_c('div',{staticClass:"position-relative",staticStyle:{"width":"fit-content"}},[_c('img',{staticStyle:{"max-height":"60vh","max-width":"600px","width":"auto","height":"auto"},attrs:{"src":_vm.files.image.preview}}),_c('div',{staticClass:"image-dot",staticStyle:{"transform":"translate(-50%)"},style:({
                top: ((_vm.form.products[index].y) + "%"),
                left: ((_vm.form.products[index].x) + "%"),
              })})]):_vm._e(),_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v("Mobile")]),_c('b-form-group',{attrs:{"label":"X%","label-for":("xmobile-" + (item.id) + "-" + (item.id))}},[_c('validation-provider',{attrs:{"name":("Xmobile #" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":("xmobile-" + (item.id) + "-" + (item.id)),"placeholder":"X%"},model:{value:(_vm.form.products[index].x_mobile),callback:function ($$v) {_vm.$set(_vm.form.products[index], "x_mobile", $$v)},expression:"form.products[index].x_mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Y%","label-for":("ymobile-" + (item.id) + "-" + (item.id))}},[_c('validation-provider',{attrs:{"name":("Ymobile #" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":("ymobile-" + (item.id) + "-" + (item.id)),"placeholder":"Y%"},model:{value:(_vm.form.products[index].y_mobile),callback:function ($$v) {_vm.$set(_vm.form.products[index], "y_mobile", $$v)},expression:"form.products[index].y_mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.files.image_mobile)?_c('div',{staticClass:"position-relative",staticStyle:{"width":"fit-content"}},[_c('img',{staticStyle:{"max-height":"60vh","max-width":"600px","width":"auto","height":"auto"},attrs:{"src":_vm.files.image_mobile.preview}}),_c('div',{staticClass:"image-dot",staticStyle:{"transform":"translate(-50%)"},style:({
                top: ((_vm.form.products[index].y_mobile) + "%"),
                left: ((_vm.form.products[index].x_mobile) + "%"),
              })})]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger","disabled":index === 0},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Usuń")])],1),_c('b-col',[_c('hr')])],1)}),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","disabled":!_vm.lang},on:{"click":_vm.addProductElement}},[_c('span',[_vm._v("Dodaj produkt")])])],1)],2)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":!_vm.lang}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }